import { CommonPaginatedRequestDataModelWithFilter, WebVerb, commonResponseMapFor } from "app/helpers/entities";
import { ReceiveInvoiceItemDataModel, ReceiveInvoicesResponseDataModel, ReceiveInvoicesResponseDto, ReceivePaymentLinkRequesParams } from "./types";

import {getBaseUrlPrefix, getPortalApi, PortalApi} from "app/api";

const isIframe = window.self !== window.top;

export const invoicesEndPoints = PortalApi.injectEndpoints({
    endpoints: builder => ({
        // use for invoices page 
        getInvoices: builder.query<ReceiveInvoicesResponseDataModel, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: '/invoices',
                method: WebVerb.POST,
                body: body
            }),
            transformResponse: (response: ReceiveInvoicesResponseDto) => commonResponseMapFor(response, item => item),
            providesTags: ["invoices-list"]
        }),
        // use for other fetching
        receiveInvoices: builder.query<ReceiveInvoicesResponseDataModel, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: '/invoices/query',
                method: WebVerb.POST,
                body: body
            }),
            transformResponse: (response: ReceiveInvoicesResponseDto) => commonResponseMapFor(response, item => item),
            providesTags: ["invoices-list-query"]
        }),

        getInvoiceById: builder.query<ReceiveInvoiceItemDataModel, { id: string }>({
            query: body => ({
                url: `/invoices/${body.id}`,
                method: WebVerb.GET
            })
        }),

        getTaxName: builder.query<{ taxName: string }, void>({
            query: () => ({
                url: `/tax-name-settings`,
                method: WebVerb.GET
            })
        }),

        getPaymentLink: builder.mutation<string, ReceivePaymentLinkRequesParams>({
            query: body => ({
                url: `/invoices/${body.invoiceId}/payment-link`,
                method: WebVerb.GET,
                params: {
                    successUrl: body.successUrl,
                    failUrl: body.failUrl,
                }
            })
        }),

        getInvoicePdf: builder.mutation<void, string>({
            query: body => ({
                url: `/invoices/${body}/pdf`,
                method: WebVerb.GET,
                responseHandler: async (response) => {
                    if (!response.ok) {
                        throw new Error("Something went wrong!")
                    }
                    return await response.blob().then(blob => {
                        const fileURL = `${getPortalApi()}${getBaseUrlPrefix()}/invoices/${body}/pdf`
                        const blobURL = window.URL.createObjectURL(blob);
                        let alink = document.createElement('a');
                        alink.href = blobURL;
                        alink.target = "_blank"
                        alink.download = `${body}.pdf`;
                        alink.click();

                        !isIframe && window.open(fileURL, '_blank');
                    })
                },
            })
        }),
    })
});

