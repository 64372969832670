import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import React, { Suspense } from 'react';

import App from './App';
import { BackdropLoader } from 'app/views/components/common/BackdropLoader';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<Suspense fallback={<BackdropLoader open />}><App /></Suspense>);

serviceWorkerRegistration.register();