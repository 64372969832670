import React, { PropsWithChildren } from "react";

import { AppUriPath } from "app/AppRoutes";
import { Avatar } from "@mui/material";
import { LinkButton } from "app/views/components/common/LinkButton";
import { NaviageToAvailablePath } from "../pages/CheckoutPage/components/NaviageToAvailablePath";
import { Navigate } from "react-router-dom";
import cn from "classnames";
import css from "./styles/styles.module.css"
import { useAppContext } from "app/providers/hooks/useAppContext";
import { useDocumentTitle } from "app/views/components/hooks/useDocumentTitle";
import { useFavicon } from "app/views/components/hooks/useFavicon";
import { useProjectInfo } from "app/views/components/hooks/useProjectInfo";

type OwnProps = PropsWithChildren & {
    hideFooter?: boolean
};

export const UnProtectedPage: React.FC<OwnProps> = ({ children, hideFooter }: OwnProps) => {
    const { langContext: { getTranslations }, authContext: { isAuthenticated } } = useAppContext();
    const { logoUrl, faviconUrl, isActive } = useProjectInfo();
    const { translate } = getTranslations("authentication");

    useFavicon(faviconUrl);
    useDocumentTitle("authenticationPageTitle");

    if (isAuthenticated) return <NaviageToAvailablePath />;
    if (!isActive) return <Navigate to={AppUriPath.notfound} />;
    return <div className={cn(css["container"])}>
        <header data-testid="header" className={cn(css["header"])}>
            <Avatar
                sx={{ width: "auto", height: "auto", maxWidth: "100px", marginRight: "16px" }}
                variant="square"
                src={logoUrl}
            />
        </header>
        <main data-testid="content" className={cn(css["main"])}>
            {children}
        </main>
        {
            !hideFooter && <footer data-testid="footer" className={cn(css["footer"])}>
                <LinkButton
                    text={translate("terms")}
                    target="_blank"
                    isLink
                    link="https://rainex.io/customer-portal-terms-of-service/"
                />
            </footer>
        }
    </div>
}